import React, {Fragment, useEffect, useState} from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import TitleBlock from "../components/TitleBlock"
import {useAxiosClient} from "../service/useAxiosClient"
import Adviser from "../components/Adviser"
import TitlePage from "../components/TitlePage"

export default function FinancialAdvisor({location}) {
	const queryParams = new URLSearchParams(location.search)
	const [searchResults, setSearchResults] = useState([])
	const [, triggerSaveData] = useAxiosClient(
		{},
		{
			manual: true,
		}
	)

	const sendToAPi = () => {
		const uc = queryParams.get("uc")
		if (!uc) return
		triggerSaveData({
			url: `/persons/web/seeker/`,
			method: "post",
			data: {
				uc: uc
			},
		})
			.then(({data, status}) => {
				if (status !== 200 || !data) return
				setSearchResults(data.items)
			})
			.catch(() => {
				setSearchResults([])
			})
	}

	useEffect(() => sendToAPi(), [])

	const renderResults = () => {
		if (!searchResults.length) return <Fragment/>

		return (
			<div className="SearchCertified-list">
				<div className="SearchCertified-container">
					<div style={{display: "flex", justifyContent: "center"}}>{renderCards()}</div>
				</div>
			</div>
		)
	}

	const renderCards = () => {
		return (
			searchResults &&
			searchResults.map(item => {
				return (
					<div>
						<Adviser
							name={item.full_name}
							code={item.associate_code}
							description={item.description}
							state={item.state}
							certification_name={item.certification_name}
							facebook={item.facebook_link}
							linkedin={item.linkedin_link}
							twitter={item.twitter_link}
						/>
					</div>
				)
			})
		)
	}
	return (
		<Layout>
			<TitlePage
				title="Busca tu asesor financiero certificado"
				url={location.pathname}
			/>
			<Hero
				image="/imgs/quienes-somos.jpg"
				text="Busca tu Asesor Financiero Certificado"
			/>
			<div className="container950">
				<div className="SearchCertified">
					<TitleBlock title="Registro EFPA España" icon="far fa-id-card"/>
					<div className="SearchCertified-content">
						{renderResults()}
					</div>
				</div>
			</div>
		</Layout>
	)
}
